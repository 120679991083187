import React, {
  useEffect, useRef, useContext, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import pym from 'pym.js';

import MobileDetection from 'lib/MobileDetection';
import { useVideoController } from 'components/VideoContainer/context/videoController';
import { useSetIsAdPlaying } from 'components/VideoContainer/context/state/isAdPlaying';
import { useSetIsVideoPaused } from 'components/VideoContainer/context/state/isPaused';
import { useSetVideoVolume } from 'components/VideoContainer/context/state/volume';
import { useSetVideoTime } from 'components/VideoContainer/context/state/time';
import { useIsReady, useSetIsReady } from 'components/VideoContainer/context/state/isReady';
import { CoreVideoTokens } from 'lib/ContextTypes/coreVideoTokens';

import { useSetSubtitles } from 'components/VideoContainer/context/state/subtitles';
import { useSetSubtitleTrack } from 'components/VideoContainer/context/state/subtitleTrack';
import { getPrivacyCookie } from 'lib/videoParamsCookie';
import { NEWS_NOW_DEFAULT_SLATE, STREAM_TO_SLATE_MAP } from 'lib/liveVideo';
import { v4 as uuidv4 } from 'uuid';
import { isBrowser } from 'lib/BrowserDetection';
import { logError } from 'lib/datadog';
import screenfull from 'screenfull';
import styles from './styles.module.scss';
import { VideoLoadingAnimation } from './Loader';
import { Slate } from './Slate';

/**
 * Parses JSON data and passes the result to the callback function.
 *
 * @param {function(object):void} callback - The callback function to handle the parsed JSON object.
 */
const parsedJson = (callback) => (data) => {
  try {
    callback(JSON.parse(data));
  } catch (e) {
    // no op
  }
};

/**
 *
 * @param {object} params
 * @param {boolean} params.autoplay
 * @param {boolean} params.mutedAutoplay
 * @param {string} params.mvpdHash
 * @param {import('lib/liveVideo').STREAM_KEY} params.stream
 * @param {string} params.playmakerArt
 */
export function CoreVideoPlayer({
  stream,
  mvpdHash,
  autoplay,
  playmakerArt: cvsdkArt,
  mutedAutoplay,
}) {
  const containerId = 'core-video';
  const pymParentRef = useRef();
  const videoController = useVideoController();
  const setIsVideoPaused = useSetIsVideoPaused();
  const setVideoVolume = useSetVideoVolume();
  const setVideoTime = useSetVideoTime();
  const setIsAdPlaying = useSetIsAdPlaying();
  const setSubtitleTrack = useSetSubtitleTrack();
  const setSubtitles = useSetSubtitles();
  const setIsReady = useSetIsReady();
  const isReady = useIsReady();
  const { timestamp, tokenByDrmType } = useContext(CoreVideoTokens);
  const [displaySlate, setDisplaySlate] = useState(true);
  const [hasError, setHasError] = useState(false);
  let mparticleId = null;

  const slate = useMemo(() => {
    if (hasError) {
      return 'https://media-cldnry.s-nbcnews.com/image/upload/q_auto:eco/f_auto/newscms/2024_02/3635347/error_code_0035.png';
    }
    return cvsdkArt ?? STREAM_TO_SLATE_MAP[stream] ?? NEWS_NOW_DEFAULT_SLATE;
  }, [cvsdkArt, hasError]);


  useEffect(async () => {
    if (isBrowser()) {
      try {
        //* Grabs the BI_UI_mpid cookie, which is the mparticle ID
        //* Falls back to a random UUID, per https://nbcnewsdigital.atlassian.net/browse/NGSP-277
        //* BI gets loaded via Adobe Launch, so eslint marks it as undefined
        // eslint-disable-next-line no-undef
        mparticleId = BI.userInfo.get('mpid') ?? uuidv4();
      } catch (error) {
        mparticleId = uuidv4();
      }
    }
    let firedReady = false;
    const usPrivacy = getPrivacyCookie();
    const sigmaUrl = new URL('/sigma.html', window.location.origin);
    sigmaUrl.searchParams.set('stream', stream);
    sigmaUrl.searchParams.set('mvpdHash', mvpdHash);
    sigmaUrl.searchParams.set('mpid', mparticleId);
    sigmaUrl.searchParams.set('timestamp', timestamp);
    sigmaUrl.searchParams.set('usPrivacy', usPrivacy);
    sigmaUrl.searchParams.set('autoplay', autoplay);
    sigmaUrl.searchParams.set('mutedAutoplay', mutedAutoplay);
    Object.keys(tokenByDrmType).forEach((drmType) => {
      sigmaUrl.searchParams.set(`token_${drmType}`, tokenByDrmType[drmType]);
    });

    pymParentRef.current = new pym.Parent(
      containerId,
      sigmaUrl.toString(),
    );

    const { current: pymParent } = pymParentRef;

    pymParent.iframe.setAttribute('allow', 'autoplay');

    pymParent.onMessage(
      'cvPlayerError',
      parsedJson((err) => {
        if (err.code === 'VSF:SDK.LOADING_LIMIT.UNKNOWN_REASON.MAIN_CONTENT') {
          // This error can occur when a video is not autoplayed and is buffered > 1m
          // See (BENTO-26806)
          pymParent.sendMessage('createPlaybackSession', '');
          firedReady = false;
        } else {
          logError(err);
          setHasError(true);
          setDisplaySlate(true);
          setIsReady(true);
        }
      }),
    );

    pymParent.onMessage(
      'onStateChanged',
      (data) => {
        try {
          const [state] = JSON.parse(data);
          setIsReady(state === 'Playing' || state === 'Paused');
          setIsVideoPaused(state !== 'Playing');
          if (state === 'Playing') {
            setDisplaySlate(false);
            // Fix iPhone defaulting to subtitles enabled
            pymParent.sendMessage('disableSubtitles');
          }
        } catch (e) {
          // no op
        }
      },
    );

    // Work around for the lack of the onStateChanged on iOS
    if (MobileDetection.iOS()) {
      pymParent.onMessage('onAdBreakDataReceived', () => {
        if (!firedReady) {
          firedReady = true;
          // The actual time to be ready occurs about a second after this event occurs, so wait
          // until then, to avoid a bug.
          setTimeout(() => {
            setIsReady(true);
          }, 1200);
        }
      });
    }

    pymParent.onMessage('onAdBreakStarted', () => {
      setIsAdPlaying(true);
    });

    pymParent.onMessage('onAdBreakFinished', () => {
      setIsAdPlaying(false);
    });

    pymParent.onMessage('onAdStarted', () => {
      setIsAdPlaying(true);
    });

    pymParent.onMessage(
      'onPlaybackTimelineUpdated',
      (data) => {
        try {
          const [{
            position,
            seekableRange: {
              end,
            },
          }] = JSON.parse(data);
          setVideoTime((oldTime) => ({
            ...oldTime,
            currentTime: position,
            duration: end,
          }));
        } catch (e) {
          // no op
        }
      },
    );

    pymParent.onMessage(
      'onVolumeChanged',
      (data) => {
        try {
          const [volume] = JSON.parse(data);
          setVideoVolume((state) => ({
            ...state,
            volume,
          }));
        } catch (e) {
          // no op
        }
      },
    );

    pymParent.onMessage(
      'onMuteChanged',
      (data) => {
        try {
          const [muted] = JSON.parse(data);
          setVideoVolume((volumeState) => ({
            ...volumeState,
            muted,
          }));
        } catch (e) {
          // no op
        }
      },
    );
    pymParent.onMessage(
      'onAvailableSubtitlesTracksChanged',
      (data) => {
        try {
          const availableSubtitleTracks = JSON.parse(data);
          setSubtitleTrack(availableSubtitleTracks[0][0].id);
        } catch (e) {
          // no op
        }
      },
    );

    pymParent.onMessage(
      'onSubtitleCuesChanged',
      (data) => {
        try {
          const subtitleContent = JSON.parse(data);
          setSubtitles(subtitleContent?.[0]?.[0]?.text ?? null);
        } catch (e) {
          // no op
        }
      },
    );

    videoController.registerPlayHandler(() => {
      pymParent.sendMessage('play');
    });

    videoController.registerPauseHandler(() => {
      pymParent.sendMessage('pause');
    });

    videoController.registerSetMuteHandler((mute) => {
      pymParent.sendMessage('setMute', JSON.stringify(mute));
    });

    videoController.registerSetVolumeHandler((volume) => {
      pymParent.sendMessage('setVolume', JSON.stringify(volume));
    });

    videoController.registerSeekToHandler((seekTime) => {
      pymParent.sendMessage('seek', JSON.stringify(seekTime));
    });

    videoController.registerEnableSubtitlesHandler((trackId) => {
      pymParent.sendMessage('enableSubtitles', JSON.stringify(trackId));
    });

    videoController.registerDisableSubtitlesHandler(() => {
      pymParent.sendMessage('disableSubtitles');
    });

    if (!screenfull.isEnabled) {
      // Assume we're in an iPhone, so use the alternate fullscreen approach
      videoController.registerToggleFullscreenHandler(() => {
        pymParent.sendMessage('enableFullscreen_iPhone');
      });
    }

    return () => {
      pymParent.remove();
      pymParentRef.current = null;
    };
  }, [mparticleId]);

  return (
    <>
      <div id={containerId} className={styles.coreVideoContainer} data-testid="core-video-container" />
      <Slate displaySlate={displaySlate} slate={slate} />
      <VideoLoadingAnimation isReady={isReady} />
    </>
  );
}

CoreVideoPlayer.propTypes = {
  stream: PropTypes.string,
  mvpdHash: PropTypes.string,
  playmakerArt: PropTypes.string,
  autoplay: PropTypes.bool,
  mutedAutoplay: PropTypes.bool,
};

CoreVideoPlayer.defaultProps = {
  stream: 'NEWS_NOW',
  mvpdHash: '',
  autoplay: false,
  playmakerArt: null,
  mutedAutoplay: false,
};
