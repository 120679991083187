import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'components/Link';
import './styles.themed.scss';
import { useRouter } from 'next/router';

const block = 'animated-ghost-button';

const typesRequired = [
  'button',
  'buttonWithArrow',
  'link',
  'subscribeButton',
];

/**
 * Renders a button or link component with hover animation based on the provided properties.
 *
 * @param {object} props - The properties for the button or link component.
 * @param {string} [props.additionalClasses] - Additional CSS classes to apply to the component.
 * @param {boolean} [props.animated=true] - Flag indicating if the button should have animation.
 * @param {boolean} [props.disabled=false] - Flag indicating if the button is disabled.
 * @param {boolean} [props.hasDarkBackground=false] - Flag indicating if the button is on a dark background.
 * @param {string} [props.iconStyle] - CSS class for the icon style.
 * @param {string} [props.id] - The ID of the button element.
 * @param {Function} [props.onClick] - Click event handler for the button.
 * @param {string} [props.size='normal'] - The size of the button.
 * @param {string} [props.target='_self'] - The target attribute for the link.
 * @param {string} props.title - The title of the button or link.
 * @param {string} props.type - The type of the component ('button', 'buttonWithArrow', 'link', 'subscribeButton').
 * @param {string} [props.url] - The URL for the link.
 * @param {string} [props.icid] - The ICID for tracking.
 * @param {boolean} [props.isInverted=false] - Flag indicating if the button is inverted.
 * @param {string} [props.buttonType='button-hover-animation'] - The type of the button for testing purposes.
 * @param {string} [props.ariaControls] - The ID of the element that the button controls.
 * @param {string} [props.ariaLabel] - The ARIA label for the button.
 * @returns {React.ReactElement|null} The rendered button or link component, or null if no onClick handler is provided.
 */
const ButtonHoverAnimation = (props) => {
  const {
    additionalClasses,
    animated,
    disabled,
    hasDarkBackground,
    iconStyle,
    id,
    onClick,
    size,
    target,
    title,
    type,
    url,
    icid,
    isInverted,
    buttonType,
    ariaControls,
    ariaLabel,
  } = props;

  const buttonClassString = classNames(
    {
      [`${block} ${block}--${size}`]: animated,
      [`${block}--darkBackground`]: hasDarkBackground,
      [`${block}--inverted`]: isInverted,
      disabled,
    },
    additionalClasses,
  );

  const { query: { page } = {} } = useRouter() || {};
  const formattedTitle = title.toLowerCase().replace(/[^a-z\s]/g, '').replace(/\s+/g, '-'); // title-like-this
  const dataActivityMap = page === 'recipe'
    ? `button-hover-animation-article-sidebar-${formattedTitle}`
    : undefined;

  if (type === 'link') {
    return (
      <Link
        className={buttonClassString}
        href={url}
        target={target}
        icid={icid}
        data-activity-map={dataActivityMap}
        data-testid={buttonType}
      >
        {title}
      </Link>
    );
  }

  if (onClick) {
    const children = [title];

    if (type === 'buttonWithArrow' && iconStyle) {
      children.push(<span className={iconStyle} />);
    }

    return (
      <button
        className={buttonClassString}
        disabled={disabled}
        id={id}
        onClick={onClick}
        type="button"
        data-activity-map={dataActivityMap}
        data-testid={buttonType}
        aria-controls={ariaControls}
        aria-label={ariaLabel}
      >
        {children}
      </button>
    );
  }

  return null;
};

ButtonHoverAnimation.propTypes = {
  additionalClasses: PropTypes.string,
  animated: PropTypes.bool,
  disabled: PropTypes.bool,
  hasDarkBackground: PropTypes.bool,
  iconStyle: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(typesRequired),
  url: PropTypes.string,
  icid: PropTypes.string,
  isInverted: PropTypes.bool,
  buttonType: PropTypes.string,
  ariaControls: PropTypes.string,
  ariaLabel: PropTypes.string,
};

ButtonHoverAnimation.defaultProps = {
  additionalClasses: null,
  animated: true,
  disabled: false,
  hasDarkBackground: false,
  iconStyle: '',
  id: null,
  onClick: null,
  size: 'normal',
  target: '_self',
  title: '',
  url: null,
  icid: null,
  type: undefined,
  isInverted: false,
  buttonType: 'button-hover-animation',
  ariaControls: '',
  ariaLabel: '',
};

export default ButtonHoverAnimation;
