/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ButtonHoverAnimation from 'components/ButtonHoverAnimation';
import { ButtonTilt } from 'components/ButtonTilt';
import { VerticalContext } from 'lib/ContextTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { BUTTON_TYPE } from 'lib/brandFeatures';

/**
 * Helper component to switch between different button styles.
 *
 * @param {object} [props] - The remaining props to pass down to the styled button.
 * @param {string} [props.buttonType] - Override the vertical default for type of component to use.
 * @param {string} [props.ariaControls] - The ID of the element that the button controls.
 * @param {string} [props.ariaLabel] - The accessible label for the button.
 * @returns {React.ReactElement} The rendered button component.
 */
const Button = ({
  buttonType, ariaControls, ariaLabel, ...props
}) => {
  const vertical = useContext(VerticalContext);
  const defaultButtonType = getFeatureConfigForBrand(
    BUTTON_TYPE,
    vertical,
  );
  const type = buttonType || defaultButtonType;

  let Component = ButtonHoverAnimation;

  if (type === 'button-tilt') {
    Component = ButtonTilt;
  }

  return (
    <Component
      data-test={type}
      buttonType={type}
      ariaControls={ariaControls}
      ariaLabel={ariaLabel}
      {...props}
    />
  );
};

Button.propTypes = {
  buttonType: PropTypes.string,
  ariaControls: PropTypes.string,
  ariaLabel: PropTypes.string,
};

Button.defaultProps = {
  buttonType: null,
  ariaControls: '',
  ariaLabel: '',
};

export { Button };
